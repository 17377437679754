import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import FormContatti from "../components/form-contatti";

import Square from "../svg/square-come-raggiungerci.svg";

const breadcrumb = [{ title: "Home", link: "/" }, { title: "Contatti" }];

const Contatti = ({ location }) => {
  return (
    <Layout location={location} breadcrumb={breadcrumb}>
      <Seo title={`Contatti`} />

      <FormContatti location={location} startOpen={true} />

      <section className="section section--come-raggiungerci pt-0">
        <Square className="square d-none d-lg-block" />
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-8 offset-lg-2 col-xl-7 offset-xl-5">
              <h2 className="section__title section__title--small">Come raggiungerci</h2>
              <p>
                Siamo in{" "}
                <span className="color-primary">Via Giovanni Perotti 4 a Trivolzio (Pv)</span>, a{" "}
                <strong>soli 15min da Milano</strong>.
              </p>
              <p>
                La nostra sede si trova nel <strong>complesso edilizio Cascina La Maggiore</strong>,
                sul versante che fiancheggia il famoso Santuario che ospita le spoglie del Santo
                Riccardo Pampuri.
              </p>
              <p>
                Per raggiungerci dunque può risultare utile seguire le indicazioni per il luogo di
                culto, famosa meta di pellegrinaggio.
                <br />
                Ci troviamo in una zona pedonale, ma facilmente raggiungibile a piedi dall'ampio
                parcheggio situato sul lato opposto.
              </p>
              <a
                href="https://www.google.com/maps/dir/Ariadne+Srl,+Via+Giovanni,+Via+Perotti,+4,+27020+Trivolzio+PV/Parcheggio,+Via+Don+M.+Gandolfi,+19,+27020+Trivolzio+PV/@45.260554,9.048422,16z/data=!4m14!4m13!1m5!1m1!1s0x4786c1648d0a7281:0xc42f09ea73f92fe3!2m2!1d9.0484191!2d45.2611334!1m5!1m1!1s0x4786df0837e1a835:0x11bdb74ae2dd16fc!2m2!1d9.0490524!2d45.2599271!3e2?hl=it"
                title="Visualizza la mappa"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link btn-link--secondary mt-5"
              >
                Visualizza la mappa
              </a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Contatti;
